import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MenuService, SettingsService, TitleService, ALAIN_I18N_TOKEN } from '@delon/theme';
import { ACLService } from '@delon/acl';
import { TranslateService } from '@ngx-translate/core';
import { I18NService } from '../i18n/i18n.service';

import { NzIconService } from 'ng-zorro-antd';
import { ICONS_AUTO } from '../../../style-icons-auto';
import { ICONS } from '../../../style-icons';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  id: any;
  url: any;
  constructor(
    private cookies: CookieService,
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    private httpClient: HttpClient,
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }

  // load(): Promise<any> {
  //   // only works with promises
  //   // https://github.com/angular/angular/issues/15088
  //   return new Promise(resolve => {
  //     zip(
  //       this.httpClient.get(`assets/tmp/i18n/${this.i18n.defaultLang}.json`),
  //       this.httpClient.get('assets/tmp/app-data.json'),
  //     )
  //       .pipe(
  //         // 接收其他拦截器后产生的异常消息
  //         catchError(([langData, appData]) => {
  //           resolve(null);
  //           return [langData, appData];
  //         }),
  //       )
  //       .subscribe(
  //         ([langData, appData]) => {
  //           // setting language data
  //           this.translate.setTranslation(this.i18n.defaultLang, langData);
  //           this.translate.setDefaultLang(this.i18n.defaultLang);

  //           // application data
  //           const res: any = appData;
  //           // 应用信息：包括站点名、描述、年份
  //           this.settingService.setApp(res.app);
  //           // 用户信息：包括姓名、头像、邮箱地址
  //           this.settingService.setUser(res.user);
  //           // ACL：设置权限为全量
  //           this.aclService.setFull(true);
  //           // 初始化菜单
  //           this.menuService.add(res.menu);
  //           // 设置页面标题的后缀
  //           this.titleService.default = '';
  //           this.titleService.suffix = res.app.name;
  //         },
  //         () => {},
  //         () => {
  //           resolve(null);
  //         },
  //       );
  //   });
  // }

  load(): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise((resolve, reject) => {
      this.viaHttp(resolve, reject);
    });
  }

  private viaHttp(resolve: any, reject: any) {
    // 多语言
    this.httpClient.get(`/assets/tmp/i18n/${this.i18n.defaultLang}.json`).subscribe(langData => {
      this.translate.setTranslation(this.i18n.defaultLang, langData);
      this.translate.setDefaultLang(this.i18n.defaultLang);
    });

    // this.id = this.cookies.get('sid');
    // if (!this.id) {
    //   this.url = environment.SPAPPS_URL + '/backend/web/v1/users/startUp';
    // } else {
    //   this.url = environment.SPAPPS_URL + '/backend/web/v1/users/start-up';
    // }
    this.url = environment.SPAPPS_URL + '/backend/web/v1/users/startUp';
    this.httpClient
      .get(this.url, {})
      .pipe(
        // 接收其他拦截器后产生的异常消息
        catchError(data => {
          resolve(null);
          return data;
        }),
      )
      .subscribe(
        (res: any) => {
          res = res.data;
          // 应用信息：包括站点名、描述、年份
          this.settingService.setApp(res.app);
          // 用户信息：包括姓名、头像、邮箱地址
          this.settingService.setUser(res.user);
          // ACL：设置权限
          this.aclService.setRole([res.user.role]);
          // 初始化菜单
          this.menuService.clear();
          this.menuService.add(res.menu);
          // 设置页面标题的后缀
          this.titleService.suffix = res.app.name;
        },
        err => console.log(err),
        () => {
          resolve(null);
        },
      );
  }
}
