import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NzMessageService, UploadFile, NzDrawerRef, NzModalControlService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { ContentImagesComponent } from '../../../images/images.component';

@Component({
  selector: 'app-content-categorylist-edit',
  templateUrl: './edit.component.html',
})
export class ContentProlistCategorylistEditComponent implements OnInit {
  validateForm: FormGroup;
  nodes: any = [];
  addObj: any;
  editObj: any;
  url: string;
  avatarUrl: string;
  picUrl = '';
  loading = false;
  media_id = '';

  constructor(
    private cookies: CookieService,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private drawerRef: NzDrawerRef,
    private modal: ModalHelper,
  ) {}

  // 获取表单数据
  formparent = '';
  formname = '';
  formsecond_name = '';
  formcontent = '';
  formicon = '';
  formlink = '';
  formstatus = '1';
  formtype = '';
  formsort = 0;
  formintro = '';
  formis_nav = 0;
  get sid() {
    return this.cookies.get('sid');
  }

  ngOnInit(): void {
    if (this.addObj) {
      console.log('addObj', this.addObj);
      this.nodes = this.addObj.cateList;
      this.url = '/backend/web/v1/productcate/addproductcate';
      this.formparent = '';
      this.formname = '';
      this.formsecond_name = '';
      this.formcontent = '';
      this.formicon = '';
      this.formlink = '';
      this.formstatus = '1';
      this.formtype = '';
      this.formsort = 0;
      this.formintro = '';
      this.formis_nav = 0;
    } else {
      console.log(this.editObj);
      this.nodes = this.editObj.cateList;
      this.avatarUrl = this.editObj.item.url;
      this.media_id = this.editObj.item.icon;
      this.url = '/backend/web/v1/productcate/editproductcate';
      //关键
      if (this.editObj.item.parent === null) {
        this.formparent = '';
      } else {
        this.formparent = this.editObj.item.parent.key;
      }
      this.formname = this.editObj.item.name;
      this.formsecond_name = this.editObj.item.second_name;
      this.formicon = this.editObj.item.icon;
      this.formlink = this.editObj.item.link;
      this.formstatus = this.editObj.item.status;
      this.formtype = this.editObj.item.type;
      this.formsort = this.editObj.item.sort;
      this.formintro = this.editObj.item.intro;
      this.formis_nav = this.editObj.item.is_nav;
    }

    // 上传图片
    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic';
    // 表单验证
    this.validateForm = this.fb.group({
      parent: [null],
      name: [null, [Validators.required]],
      second_name: [null],
      icon: [null],
      link: [null],
      intro: [null],
      status: [1, [Validators.required]],
      is_nav: [0, [Validators.required]],
      type: [null],
      sort: [null,[Validators.required]],
    });
  }
  // 表单提交
  submitForm(): void {
    let form = {
      parent: this.formparent,
      name: this.formname,
      second_name: this.formsecond_name,
      icon: this.media_id.toString(),
      intro: this.formintro,
      link: this.formlink,
      status: this.formstatus,
      type: this.formtype,
      sort: this.formsort,
      is_nav: this.formis_nav,
      sid: this.sid,
    };
    if (this.editObj) {
      form['id'] = this.editObj.item.key;
    }
    console.log(this.validateForm.valid);
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      this.http.post(environment.SPAPPS_URL + this.url, form).subscribe((res: any) => {
        console.log(res);
        if (res.code !== 1) {
          this.msg.error(res.message);
          return;
        }
        this.msgSrv.success('操作成功，已成功保存数据.');
        this.drawerRef.close();
      });
    }
  }

  checkImages() {
    this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      console.log(res);
      this.avatarUrl = res.url;
      this.media_id = res.media_id;
    });
  }
}
