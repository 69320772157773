import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { AdminBaseComponent } from "../../admin/base/base.component"
@Component({
  selector: 'app-dashboard-v1',
  templateUrl: './v1.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardV1Component implements OnInit {
  procedures = {
    avatarUrl:"",
    title: "",
    keyWord: "",
    des: "",
  }
  info = {
    sale_total: 0,
    vip_total: 0,
    order_total: 0,
    pro_total:0,
   }
//
  webSite: any[];
  salesData: any[];
  offlineChartData: any[];

  constructor(
    private http: _HttpClient,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.http.get('/chart').subscribe((res: any) => {
      console.log(res)
      this.webSite = res.visitData.slice(0, 10);
      this.salesData = res.salesData;
      this.offlineChartData = res.offlineChartData;
      this.cdr.detectChanges();
    });
    this.getinfo()
  }
  getinfo() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/homepage/home").subscribe(res => {
      if (res.code === 1) {
        this.info.sale_total = res.data.sale_total;
        this.info.vip_total = res.data.vip_total;
        this.info.order_total = res.data.order_total;
        this.info.pro_total = res.data.pro_total;

        this.procedures.title = res.data.$small_wechat.instance_name;
        this.procedures.keyWord = res.data.$small_wechat.keyword;
        this.procedures.des = res.data.$small_wechat.describe;
        this.procedures.avatarUrl=res.data.$small_wechat.two_code
      }
    })
  }
}
