import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STColumnBadge } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { environment } from '@env/environment';
import { ContentNewslistEditComponent } from './edit/edit.component';
import { NzModalService, NzMessageService, NzDrawerService } from 'ng-zorro-antd';
import { SFSchemaEnumType } from '@delon/form';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

const BADGE1: STColumnBadge = {
  0: { text: '禁用', color: 'warning' },
  1: { text: '正常', color: 'success' },
};
@Component({
  selector: 'app-content-newslist',
  templateUrl: './newslist.component.html',
})
export class ContentNewslistComponent implements OnInit {
  cateList: any;
  url = environment.SPAPPS_URL + '/backend/web/v1/content/contentlist?sid=' + this.sid;
  searchSchema: SFSchema = {
    properties: {
      title: {
        type: 'string',
        title: '文章标题',
        ui: {
          width: 260,
          allowClear: true,
          placeholder: '请选择文章标题',
        },
      },
      cate_id: {
        type: 'string',
        title: '文章分类',
        ui: {
          widget: 'tree-select',
          width: 260,
          allowClear: true,
          placeholder: '请选择文章所属分类',
          dropdownStyle: { height: '200px' },
          defaultExpandAll: true,
          asyncData: () => this.getTreeData(),
        },
      },
    },
  };

  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '标题', index: 'title' },
    { title: '分类', index: 'name', width: '10%' },
    { title: '状态', type: 'badge', index: 'status', badge: BADGE1, width: '10%' },
    { title: '浏览量', index: 'views', width: '10%',className: 'text-center' },
    { title: '排序', index: 'sort', width: '10%',className: 'text-center' },
    {
      title: '操作',width:'15%',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.delete(item.id) },
      ],
    },
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private message: NzMessageService,
    private modalService: NzModalService,
    private cookies: CookieService,
    private drawerService: NzDrawerService,
  ) {}

  ngOnInit() {
    this.getTreeData();
    console.log('sid=', this.sid);
  }

  //获取sid
  get sid() {
    return this.cookies.get('sid');
  }
  // 获取父节点
  getTreeData() {
    return this.http.get(environment.SPAPPS_URL + '/backend/web/v1/contentcate/listcontentcate?sid=' + this.sid).pipe(
      map((v, i) => {
        const arr: SFSchemaEnumType[] = [];
        (v as any).data.list.forEach(element => {
          arr.push(element);
        });
        console.log('文章分类', arr);
        this.cateList = arr;
        return arr;
        console.log("cate",this.cateList)
      }),
    );
  }
  add() {
    this.drawerService
      .create<ContentNewslistEditComponent, { addObj: any }, string>({
        nzTitle: '添加文章分类',
        nzContent: ContentNewslistEditComponent,
        nzWidth: '80%',
        nzContentParams: {
          addObj: { id: 0, catelist: this.cateList },
        },
      }).afterClose.subscribe(() => this.st.reload());
  }
  edit(item) {
    this.drawerService
      .create<ContentNewslistEditComponent, { editObj: any }, string>({
        nzTitle: '编辑文章分类',
        nzContent: ContentNewslistEditComponent,
        nzWidth: '80%',
        nzContentParams: {
          editObj: { item: item, catelist: this.cateList },
        },
      })
      .afterClose.subscribe(() => this.st.reload());
  }
  delete(id) {
    console.log(id);
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该信息?</b>',
      nzContent: '<p>删除以后无法恢复，删除前请慎重考虑!</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/content/deletecontent', { id })
          .subscribe((res: any) => {
            if (res.code === 1) {
              this.message.success('操作完成，已成功删除该信息');
              this.st.reload();
            } else {
              this.message.error(res.message);
            }
          });
      },
    });
  }
}
