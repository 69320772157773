import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { environment } from '@env/environment';
import { CookieService } from "ngx-cookie-service";
import { read } from 'fs';
@Component({
  selector: 'app-admin-base',
  templateUrl: './base.component.html',
})
export class AdminBaseComponent implements OnInit {
  avatarUrl = ""
  procedures = {
    avatarUrl:"",
    title: "",
    keyWord: "",
    des: "",
  }
  info = {
    sale_total: 0,
    vip_total: 0,
    order_total: 0,
    pro_total:0,
   }

  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private modal: ModalHelper
  ) { }
  get sid() {
    return this.cookies.get('sid');
  }
  ngOnInit() {
    this.getinfo();
  }
  getinfo() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/homepage/home").subscribe(res => {
      if (res.code === 1) {
        this.info.sale_total = res.data.sale_total;
        this.info.vip_total = res.data.vip_total;
        this.info.order_total = res.data.order_total;
        this.info.pro_total = res.data.pro_total;

        this.procedures.title = res.data.$small_wechat.instance_name;
        this.procedures.keyWord = res.data.$small_wechat.keyword;
        this.procedures.des = res.data.$small_wechat.describe;
        this.procedures.avatarUrl=res.data.$small_wechat.two_code
      }
    })
  }
}
