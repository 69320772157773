import { Component, OnInit,ChangeDetectorRef, Input } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import { UploadFile } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
})
export class FileComponent implements OnInit {
  @Input() file_url: string;
  @Input() type: string;
  @Input() wx_mch_id: string;
  loading:boolean;
  fileUrl = "";
  file : UploadFile ;
  uploading = false;

  constructor(
    private http: _HttpClient,
    private msg: NzMessageService,
    private cdr:ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.fileUrl=environment.SPAPPS_URL + "/backend/web/v1/commons/uploadCert?type="+this.type+"&wx_mch_id="+this.wx_mch_id+"&file="+this.file
  }
  beforeUpload = (file: UploadFile) => {
    this.loading = true;
    console.log(file)
    this.fileUrl=environment.SPAPPS_URL + "/backend/web/v1/commons/uploadCert?type="+this.type+"&wx_mch_id="+this.wx_mch_id+"&file="+file
    this.cdr.detectChanges();
    };
    handleChange(info): void {
      console.log("change", info)
      if (info.file.response) {
        if (info.file.response.data) {
          this.file_url = info.file.response.data;
          this.msg.success('文件上传成功');
        } else{
          this.msg.error(info.file.response.message)
        }
      }
      if (info.file.status) {
        switch (info.file.status) {
          case 'uploading':
            this.loading = false;
            break;
          case 'done':
            this.loading = false;
            break;
          case 'error':
            this.msg.error('文件上传失败，系统未知错误，请联系客服进行反馈!');
            this.loading = false;
            break;
        }
       }
      }
}
