import { Component, OnInit } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from "@env/environment";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-content-imglist-edit',
  templateUrl: './edit.component.html',
})
export class ContentImglistEditComponent implements OnInit {
  record: any = {};
  nodes:any[] = [];
  validateForm: FormGroup;

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cookies: CookieService,
    private fb: FormBuilder,
  ) {}

    // 获取表单数据
    get category_id() {
      return this.validateForm.controls.category_id;
    }
    get name() {
      return this.validateForm.controls.name;
    }

  get sid(){
    return this.cookies.get('sid');
  }

  ngOnInit(): void {
    this.getTreeData();
    this.validateForm = this.fb.group({
      category_id: [null],
      name: [null, [Validators.required]],
    });
  }

  submitForm() {
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    else{ 
      this.http
        .put(environment.SPAPPS_URL + '/backend/web/v1/medias/'+this.record.media_id, {
          category_id: this.category_id.value,
          name: this.name.value,
          sid: this.sid,
        }).subscribe(res => {
          if (res.code !== 1) {
            this.msgSrv.error(res.message);
            return;
          }
          this.msgSrv.success('修改成功');
          this.modal.close(true);
      });
    }
  }


  // 获取父节点
  getTreeData() {
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/categories?symbol=pic_cate&sid=' + this.sid)
      .subscribe(res => {
        this.nodes = res.data;
      });
  }

  close() {
    this.modal.destroy();
  }
}
