import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ContentImagesComponent } from '../images/images.component';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-content-baseconfig',
  templateUrl: './baseconfig.component.html',
})
export class ContentBaseconfigComponent implements OnInit {
  validateForm: FormGroup;
  media_id: any;
  avatarUrl: string;
  loading = false;
  form = {
    title: "",
    keyWord: "",
    media_id: "",
    des: "",
    company: "",
    companyPhone: "",
    address:"",
    phoneNumber: "",
    qq: "",
    user_id:"",
    sid:"",
    instance_id: "",
  }
  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private fb: FormBuilder,
    private cookies: CookieService,
    private message:NzMessageService,
  ) { }
  get sid() {
    return this.cookies.get("sid");
}
  ngOnInit() {
    this.getData()
    this.validateForm = this.fb.group({
      title: [null, [Validators.required]],
      keyWord: [null, [Validators.required]],
      des: [null, [Validators.required]],
      company: [null, [Validators.required]],
      companyPhone: [null, [Validators.required]],
      address: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      qq: [null, [Validators.required]],
    });
   }
  getData() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/app-config/app-list?sid="+this.sid).subscribe(res => {
     console.log(res)
      if (res.code === 1) {
        this.avatarUrl = res.data.media,
        this.form.media_id=res.data.two_code,
        this.form.title = res.data.instance_name
        this.form.keyWord= res.data.keyword
        this.form.des= res.data.describe,
        this.form.company=res.data.com_brief,
        this.form.companyPhone=res.data.office_phone,
        this.form.address=res.data.address,
        this.form.phoneNumber = res.data.phone,
        this.form.qq = res.data.qq,
        this.form.user_id=res.data.user_id,
        this.form.instance_id= res.data.instance_id
      }
    })
  }
    // 提交
    submitForm(): void {
      console.log(this.validateForm.valid);
      if (!this.validateForm.valid) {
        for (const i in this.validateForm.controls) {
          this.validateForm.controls[i].markAsDirty();
          this.validateForm.controls[i].updateValueAndValidity();
        }
      } else {
        this.http.post(environment.SPAPPS_URL + "/backend/web/v1/app-config/app-update", {
              "user_id": this.form.user_id,
              "sid": this.sid,
              "instance_id": this.form.instance_id,
              "instance_name": this.form.title,
              "keyword": this.form.keyWord,
              "describe": this.form.des,
              "com_brief": this.form.company,
              "address": this.form.address,
              "qq": this.form.qq,
              "two_code": this.form.media_id,
              "office_phone": this.form.companyPhone,
              "phone":this.form.phoneNumber
        }).subscribe(res => {
          if (res.code === 1) {
            this.message.success(res.message)
          } else {
            this.message.error(res.message)
          }
        })
      }
    }
  //挑选图片
  checkImages() {
      this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      this.avatarUrl = res.url;
      this.form.media_id = res.media_id;
    });
  }
}

