import { Component, OnInit,ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder,AbstractControl,FormControl } from '@angular/forms';
import { NzMessageService, NzDrawerRef } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
declare const UE: any;
  export interface TreeNodeInterface {
    key: number;
    name: string;
    expand: boolean;
    children?: TreeNodeInterface[];
  }
  @Component({
    selector: 'app-content-newslist-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.less'],
  })

  export class ContentNewslistEditComponent implements OnInit {
    //富文本组件，初始化传递form.content值，提交时直接this.child1.content读取组件的值
    @ViewChild("childA") child1;
     // 封面图与相册
    @ViewChild("childB") child2;
    image = [];
  loading = false;
  // avatarUrl: string;
  addObj: any;
  editObj: any;
  item: any;
  cateList: any;
  validateForm: FormGroup;
  modalTitle: string;
  url: any;
  paramsList:any[] = [];
  categoryid:string = '';
  editorConfig:any = {};

  //动态表单
  listOfControl: Array<{ id: number; controlSelect:string; controlInstance: string;paramsName:string; paramsValue:string}> = [];

  addField(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const id = this.listOfControl.length > 0 ? this.listOfControl[this.listOfControl.length - 1].id + 1 : 0;
    console.log(id);
    const control = {
      id,
      controlSelect: `passselect${id}`,
      controlInstance: `passenger${id}`,
      paramsName:'',
      paramsValue:''
    };
    console.log(control);
    const index = this.listOfControl.push(control);

    this.validateForm.addControl(
      this.listOfControl[index - 1].controlInstance,
      new FormControl(null, Validators.required)
    );
    this.validateForm.addControl(
      this.listOfControl[index - 1].controlSelect,
      new FormControl(null, Validators.required)
    );
  }


  removeField(i: { id: number; controlSelect:string; controlInstance: string;paramsName:string; paramsValue:string }, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControl.length > 0) {
      const index = this.listOfControl.indexOf(i);
      this.listOfControl.splice(index, 1);
      console.log(this.listOfControl);
      this.validateForm.removeControl(i.controlSelect);
      this.validateForm.removeControl(i.controlInstance);
    }
  }

  getFormControl(name: string): AbstractControl {
    return this.validateForm.controls[name];
  }

  //----------弹框数据-------------
  form: any;
  initForm = {
    title: '',
    second_title: '',
    cover: 1,
    content: '',
    intro: '',
    link: '',
    cate_id: '',
    seo: '',
    description: '',
    keywords: '',
    status: 1,
    is_hot: 1,
    sort: 0,
    is_home: 1,
    views: 0,
    tel: '',
    phone: '',
    params:[],
  };
  constructor(
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private formBuilder: FormBuilder,
    private msg: NzMessageService,
    private cookies: CookieService,
    private drawerRef: NzDrawerRef,
    private modal: ModalHelper,
  ) {}

  // 获取表单数据
  get sid() {
    return this.cookies.get('sid');
  }

  // ///所属分类选择事件
  // changeCategory(value:string){
  //    this.categoryid = value;
  //    this.getParams();

  // }

  ///获取参数
  getParams(){
     this.http.get( environment.SPAPPS_URL+ '/backend/web/v1/article/articleparams?sid='+this.sid+'&c_id='+this.categoryid).subscribe(
       (res:any)=>{
           //console.log('参数列表为：'+ JSON.stringify(res));
           //清空已选定的值
           this.listOfControl.forEach(element=>{
             element.paramsName = null;
             element.paramsValue = null;
            }
           );
          this.paramsList = res.data;
       }
     );
  }

  ngOnInit(): void {
       // 表单验证
       this.validateForm = this.formBuilder.group({
        title: [null, [Validators.required]],
        second_title: [null],
        content: [null],
        intro: [null],
        link: [null],
        cate_id: [null, [Validators.required]],
        status: [1, [Validators.required]],
        is_hot: [1, [Validators.required]],
        is_home: [1, [Validators.required]],
        sort: [null,[Validators.required]],
        seo: [null],
        description: [null],
        keywords: [null],
        views: [null],
        tel: [null],
        phone: [null],
      });
    ///获取参数信息
    if (this.addObj) {
      this.cateList = this.addObj.catelist;
      this.form = this.initForm;
      this.image = []
      this.getParams();
      this.getFormControl('cate_id').valueChanges.subscribe(
         value => {
            this.categoryid = value;
            this.getParams();
        }
      );
    }
    else{
      this.cateList = this.editObj.catelist;
      this.form = this.editObj.item;
      console.log(this.form)
      this.image = [{
        media: this.form.cover,
        url:this.form.url,
      }]
      // this.avatarUrl = this.editObj.item.url;
      // this.media_id = this.editObj.item.cover;
      this.categoryid = this.editObj.item.cate_id;
      this.http.get( environment.SPAPPS_URL+ '/backend/web/v1/article/articleparams?sid='+this.sid+'&c_id='+this.categoryid).subscribe(
        (res:any)=>{
           this.paramsList = res.data;
            //开始创建参数
            console.log('paramslist',this.paramsList);
            if(this.editObj.item.params && this.editObj.item.params.length>0)
            {
                this.editObj.item.params.forEach(element=>{
                   const id = this.listOfControl.length > 0 ? this.listOfControl[this.listOfControl.length - 1].id + 1 : 0;
                   const paramId = element.paramId;
                   const paramValue = element.paramValue;
                   const control = {
                    id,
                    controlSelect: `passselect${id}`,
                    controlInstance: `passenger${id}`,
                    paramsName: paramId,
                    paramsValue:paramValue,
                    };
                    console.log('控件:',control);
                    const index = this.listOfControl.push(control);
                    this.validateForm.addControl(
                      this.listOfControl[index - 1].controlInstance,
                      new FormControl(paramId, [Validators.required])
                    );
                    this.validateForm.addControl(
                      this.listOfControl[index - 1].controlSelect,
                      new FormControl(paramValue, [Validators.required])
                    );
                });
            }
            //绑定事件
            this.getFormControl('cate_id').valueChanges.subscribe(
              value => {
                 this.categoryid = value;
                 this.getParams();
             }
           );
        }
      );
    }
  }

  close() {
    this.drawerRef.close();
  }

  add(form) {
    this.http.post(environment.SPAPPS_URL + '/backend/web/v1/content/addcontent', form).subscribe(res => {
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      this.msgSrv.success('操作完成，已成功编辑该模板信息!');
      this.drawerRef.close();
    });
  }
    edit(form) {
    form['id'] = this.form.id;
    this.http.post(environment.SPAPPS_URL + '/backend/web/v1/content/editcontent', form).subscribe(res => {
      if (res.code !== 1) {
        this.msg.error(res.message);
        return;
      }
      this.msgSrv.success('操作完成，已成功编辑该模板信息!');
      this.drawerRef.close();
    });
  }

  submitForm() {
    console.log(this.validateForm.valid);
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }

    } else {
      // 提交表单要传送的数据
      this.form.params = [];
      //获取参数
      if(this.listOfControl.length>0)
      {
         this.listOfControl.forEach(element=>{
            const controlInstance = element.controlInstance;
            const controlSelect = element.controlSelect;
            const param = {paramId:this.validateForm.controls[controlSelect].value,
               paramValue: this.validateForm.controls[controlInstance].value};
            this.form.params.push(param);
         });
      }
      console.log(this.form.params);
      let form = {
        title: this.form.title,
        second_title: this.form.second_title,
        cover: this.child2.image[0].media,
        content:this.child1.content,
        intro: this.form.intro,
        link: this.form.link,
        cate_id: this.form.cate_id,
        status: this.form.status,
        is_hot: this.form.is_hot,
        is_home: this.form.is_home,
        sort: this.form.sort,
        seo: this.form.seo,
        description: this.form.description,
        keywords: this.form.keywords,
        views: this.form.views,
        tel: this.form.tel,
        phone: this.form.phone,
        sid: this.sid,
        params:this.form.params
      };
      if (this.addObj) {
        this.add(form);
      } else {
        this.edit(form);
      }
    }
  }

  ///获取分类
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  collapse(array: TreeNodeInterface[], data: TreeNodeInterface, $event: boolean): void {
    if ($event === false) {
      if (data.children) {
        data.children.forEach(d => {
          const target = array.find(a => a.key === d.key)!;
          target.expand = true;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }
  convertTreeToList(root: object): TreeNodeInterface[] {
    const stack: any[] = [];
    const array: any[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: true });

    while (stack.length !== 0) {
      const node = stack.pop();
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({ ...node.children[i], level: node.level + 1, expand: true, parent: node });
        }
      }
    }
    return array;
  }
  visitNode(node: TreeNodeInterface, hashMap: { [key: string]: any }, array: TreeNodeInterface[]): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }
}
