import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import {environment} from "@env/environment";
import {Router} from "@angular/router";
import { NzModalService,NzMessageService } from 'ng-zorro-antd';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { zip } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MenuService, SettingsService, TitleService, ALAIN_I18N_TOKEN } from '@delon/theme';
import { ACLService } from '@delon/acl';
import { TranslateService } from '@ngx-translate/core';
// import { I18NService } from '../../i18n/i18n.service';
import { SocialService, SocialOpenType, ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { NzIconService } from 'ng-zorro-antd';
import { ICONS_AUTO } from '../../../../style-icons-auto';
import { ICONS } from '../../../../style-icons';
import { CookieService } from "ngx-cookie-service";
import { ReuseTabService } from '@delon/abc';



@Component({
  selector: 'app-step-sitelist',
  templateUrl: './sitelist.component.html',
  styleUrls: ['./sitelist.component.less'],
})
export class StepSitelistComponent implements OnInit {
  q: any = {
    status: '',
    name:'',
    app_type: 1,
  };

  loading = false;
  data: any[] = [];
  total = 0;

  constructor(
    private cookies: CookieService,
    iconSrv: NzIconService,
    private menuService: MenuService,
    private translate: TranslateService,
    // @Inject(ALAIN_I18N_TOKEN) private i18n: I18NService,
    private settingService: SettingsService,
    private aclService: ACLService,
    private titleService: TitleService,
    private httpClient: HttpClient,
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,

    private http: _HttpClient,
    private modal: ModalHelper,
    private router: Router,
    private modalService: NzModalService,
    private msg: NzMessageService,
     private cdr: ChangeDetectorRef,  //检测组件变化
  ) {
    iconSrv.addIcon(...ICONS_AUTO, ...ICONS);
  }

  load(): Promise<any> {


    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise((resolve, reject) => {

    //重新获取token
      // this.viaToken(resolve, reject);
    //重新获取菜单
      this.viaHttpLoad(resolve, reject);
    });
  }




  private viaToken(resolve: any, reject: any) {

    this.httpClient
      .get(environment.SPAPPS_URL + '/backend/web/v1/users/viaToken')
      .pipe(
        // 接收其他拦截器后产生的异常消息
        catchError(data => {
          resolve(null);
          return data;
        }),
      )
    .subscribe(
    (res: any) => {
         // 清空路由复用信息
         this.reuseTabService.clear();
         // 设置用户Token信息
         this.tokenService.set(res.data);
         // 重新获取 StartupService 内容，我们始终认为应用信息一般都会受当前用户授权范围而影响
        //  this.startupSrv.load().then(() => {
        //    let url = this.tokenService.referrer!.url || '/';
        //    if (url.includes('/passport')) {
        //      url = '/';
        //    }

        //    if(res.data.role =="super_admin" || res.data.role =="admin"){
        //        url = 'dashboard/v1';

        //        // url = 'admin/base';
        //    }else{
        //        url = 'sites/list';
        //    }


        //    this.router.navigateByUrl(url);
        //  });
    },
    err => console.log(err),
    () => {
      resolve(null);
    },

   );
  }




  private viaHttpLoad(resolve: any, reject: any)
   {

    this.httpClient
      .get(environment.SPAPPS_URL + '/backend/web/v1/users/startUp')
      .pipe(
        // 接收其他拦截器后产生的异常消息
        catchError(data => {
          resolve(null);
          return data;
        }),
      )
    .subscribe(
    (res: any) => {
      //更新token

      // this.tokenService.set(res.data);

      //存cookie
      // set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'Strict'): void;
      // this.cookies.set("sid",id,new Date(new Date().getTime() + 72*60*60*1000),'/','spapps.cn');
      res = res.data;
      // 应用信息：包括站点名、描述、年份
      this.settingService.setApp(res.app);
      // 用户信息：包括姓名、头像、邮箱地址
      this.settingService.setUser(res.user);
      // ACL：设置权限
      this.aclService.setRole([res.user.role]);
      // 初始化菜单
      this.menuService.clear();
      this.menuService.add(res.menu);
      // 设置页面标题的后缀
      this.titleService.suffix = res.app.name;

      // this.router.navigateByUrl('/admin/base');
      // url = 'dashboard/v1';
      this.router.navigateByUrl('dashboard/v1');
    },
    err => console.log(err),
    () => {
      resolve(null);
    },

   );
  }

  ngOnInit():void {


    //回到列表  删除sid
    //清除cookie
    this.cookies.delete('sid','/','smartprogram.spapps.cn');
    this.cookies.delete('sid','/','.spapps.cn');
    this.cookies.delete('sid','/','localhost');
    this.cookies.delete('sid');
    //刷新权限
    this.load();
    this.getData(1);
  }

  alertMsg(){
    this.msg.warning('审核完成后，无法更改公司信息和更换模板.');
  }


  getData(page:number) {
    this.loading = true;
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/instances'+'?page='+page+'&per-page=10',{
      app_type:this.q.app_type,
      status:this.q.status,
      name:this.q.name}).subscribe((res: any) => {
      this.data = res.data.list;
      this.total = res.data.pagination.total;
      console.log(this.total);
      this.loading = false;
      this.cdr.detectChanges();
    });

  }

  //筛选
  filterStatus(item:any){
      this.q.status = item;
      this.getData(1);
  }

  changeTabs(type:any)
  {
    this.q.app_type = type;
    this.getData(1);
  }

  //根据关键词查询
  searchData() {
     this.getData(1);
  }

  manager(id:any): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise((resolve, reject) => {
      this.viaHttp(resolve, reject,id);
    });
  }

  private viaHttp(resolve: any, reject: any,id:any) {

    this.httpClient
      .get(environment.SPAPPS_URL + '/backend/web/v1/users/start-up?sid='+ id)
      .pipe(
        // 接收其他拦截器后产生的异常消息
        catchError(data => {
          resolve(null);
          return data;
        }),
      )
  // manager(id:any){
  //   this.http.get(environment.SPAPPS_URL + '/backend/web/v1/users/start-up?instance_id='+ id)
    .subscribe(
    //   (res: any) => {
    //   if(res.code !== 1){
    //     this.msg.error(res.message);
    //      return;
    //   }else{

    //     this.router.navigateByUrl('/content/categorylist');
    //   }
    // });
    (res: any) => {


      //点击进入店铺管理  重新获取token   由customer 变成 basic
      //更新token

    // this.tokenService.set(res.data);



      //存cookie
      // set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'Strict'): void;
      // this.cookies.set("sid",id,new Date(new Date().getTime() + 72*60*60*1000),'/','spapps.cn');
      this.cookies.set("sid",id,new Date(new Date().getTime() + 72*60*60*1000));
      // this.cookies.set("sid",id,new Date(new Date().getTime() + 72*60*60*1000), '/','.spapps.cn')

      res = res.data;
      // 应用信息：包括站点名、描述、年份
      this.settingService.setApp(res.app);
      // 用户信息：包括姓名、头像、邮箱地址
      this.settingService.setUser(res.user);
      // ACL：设置权限
      this.aclService.setRole([res.user.role]);
      // 初始化菜单
      this.menuService.clear();
      this.menuService.add(res.menu);
      // 设置页面标题的后缀
      this.titleService.suffix = res.app.name;

      // this.router.navigateByUrl('/admin/base');
      this.router.navigateByUrl('dashboard/v1');
    },
    err => console.log(err),
    () => {
      resolve(null);
    },

   );
  }
  //删除
  deleteItem(item:any){
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该小程序?</b>',
      nzContent: '<p>删除以后无法恢复，删除前请慎重考虑</p>',
      nzOnOk: () => {
        this.http.delete(environment.SPAPPS_URL + '/backend/web/v1/instances/'+ item)
          .subscribe((res: any) => {
            if(res.code == 1){
              this.msg.success('操作完成，已成功删除该信息');
              this.getData(1);
            }else{
              this.msg.error(res.message);
            }
          });
      }
    });
  }

}
