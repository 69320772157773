import {Component,OnInit,ViewChild,EventEmitter,Output,Input,TemplateRef,ChangeDetectionStrategy,OnChanges,OnDestroy,ElementRef} from '@angular/core';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { UEditorComponent } from 'ngx-ueditor';
declare const UE: any;
@Component({
  selector: 'app-ueditor',
  templateUrl: './ueditor.component.html',
})
export class UeditorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() content: any;
  // 富文本
  editorConfig:any = {};
  @ViewChild('full')
  full: UEditorComponent;
  @ViewChild("childC") child3;
  uedit = {
    // media:"",
    // url:""
  };
 // 获取表单数据
 get sid() {
  return this.cookies.get('sid');
}
constructor(
  private cookies: CookieService,
) {}
  ngOnInit() {
    this.editorConfig = {
      wordCount: true,
      serverUrl: environment.SPAPPS_URL + '/backend/web/ueditor/php/controller.php?sid=' + this.sid
     }
  }
  ngOnChanges() {}
  ngOnDestroy() {}

  config: any = {
    toolbars: [
      [
        'source',
        '|',
        'undo',
        'redo',
        'indent',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'forecolor',
        'backcolor',
        'justifyleft',
        'justifycenter',
        'justifyright',
        'justifyjustify',
        'insertorderedlist',
        'insertunorderedlist',
        'blockquote',
        'emotion',
        'link',
        'removeformat',
        '|',
        'rowspacingtop',
        'rowspacingbottom',
        'lineheight',
        '|',
        'customstyle',
        'paragraph',
        'fontfamily',
        'fontsize',
        '|',
        'inserttable',
        'deletetable',
        'insertparagraphbeforetable',
        'insertrow',
        'deleterow',
        'insertcol',
        'deletecol',
        'mergecells',
        'mergeright',
        'mergedown',
        'splittocells',
        'splittorows',
        'splittocols',
        'charts',
        'imagenone',
        'imageleft',
        'imageright',
        'imagecenter',
      ],
    ],
    initialFrameWidth :"970",
    autoClearinitialContent: true,
    wordCount: false,
  };
  onPreReady(comp) {
    this.setUeditor()
  }
  getAllHtml() {
    console.log(this.full.Instance.getAllHtml())
  }

    //富文本
    setUeditor() {
      let that = this;
      UE.registerUI('button', function(editor, uiName) {
        //注册按钮执行时的command命令，使用命令默认就会带有回退操作
        editor.registerCommand(uiName, {
          execCommand: function() {
            alert('execCommand:' + uiName)
          }
        });
        //创建一个button
        var btn = new UE.ui.Button({
          name: "上传图片",
          title: "请选择图片",
          //添加额外样式，指定icon图标，这里默认使用一个重复的icon    background-image: url(../images/xiuxiu.png)
          cssRules:"width:23px ;height:19px;background-position:-726px -78px",
          //点击时执行的命令
          onclick: function () {
            that.child3.isVisible = true;
            that.child3.isListFlag = [];
            that.child3.listFlag = [];
          }
        });
        //当点到编辑内容上时，按钮要做的状态反射
        editor.addListener('selectionchange', function() {
          var state = editor.queryCommandState(uiName);
          if (state == -1) {
            btn.setDisabled(true);
            btn.setChecked(false);
          } else {
            btn.setDisabled(false);
            btn.setChecked(state);
          }
        });
        //因为你是添加button,所以需要返回这个button
        return btn;
      });

  }
    //接收选择富文本图片
    choiceImage(e) {
      let picurl=""
      e.forEach(ele => {
        picurl = '<img src="' + ele.url + '"/>';
        this.full.Instance.focus();
        this.full.Instance.execCommand('inserthtml', picurl);
      });
    }
 }
