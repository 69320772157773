import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-content-imglist-categorylist-edit',
  templateUrl: './edit.component.html',
})
export class ContentImglistCategorylistEditComponent implements OnInit {
  record: any = {};
  validateForm: FormGroup;
  nodes:any[] = [];
  parentID:any = '';

  // 获取表单数据
  get parent() {
    return this.validateForm.controls.parent;
  }
  get name() {
    return this.validateForm.controls.name;
  }
  get sid() {
    return this.cookies.get('sid');
  }

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private cookies: CookieService,
  ) {}

  ngOnInit(): void {
    this.getTreeData();
    this.validateForm = this.fb.group({
      parent: [null],
      name: [null, [Validators.required]],
    });

  }

  submitForm() {
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    }
    else{ 
      this.http
        .post(environment.SPAPPS_URL + '/backend/web/v1/categories', {
          id: this.record.key,
          symbol:"pic_cate",
          category_pid: this.parent.value,
          name: this.name.value,
          sid: this.sid,
        }).subscribe(res => {
          if (res.code !== 1) {
            this.msgSrv.error(res.message);
            return;
          }
          this.msgSrv.success('保存成功');
          this.modal.close(true);
      });
    }
  }

    // 获取父节点
    getTreeData() {
      this.http
        .get(environment.SPAPPS_URL + '/backend/web/v1/categories?symbol=pic_cate&sid=' + this.sid)
        .subscribe(res => {
          this.nodes = res.data;
        });
    }

  close() {
    this.modal.destroy();
  }
}
