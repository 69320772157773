import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NzMessageService, NzModalService, NzDrawerService } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { ContentProlistEditComponent } from './edit/edit.component';

@Component({
  selector: 'app-content-prolist',
  templateUrl: './prolist.component.html',
  styleUrls: ['./prolist.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProlistComponent implements OnInit {
  list: any = [];
  loading = true;
  pageNumber = 1;
  pageSize = 10;
  total: number;
  nodes: any = [];
  cate_id = '';
  searchKey: string = '';

  isVisible1 = false;
  showImgName: string;
  showImgUrl: string;
  modalWidth = 520;
  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    public message: NzMessageService,
    private cdr: ChangeDetectorRef,
    private cookies: CookieService,
    private modalService: NzModalService,
    private drawer: NzDrawerService,
  ) {}
  ngOnInit() {
    this.getTreeData();
    this.getData();
  }
  //获取sid
  get sid() {
    return this.cookies.get('sid');
  }
  // 获取所属分类
  getTreeData() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/productcate/listproductcate?sid=' + this.sid)
      .subscribe(res => {
        console.log('treeData', res);
        this.nodes = res.data.list;
        this.cdr.detectChanges();
      });
  }
  getData() {
    this.loading = true;
    this.http
      .get(
        environment.SPAPPS_URL +
          '/backend/web/v1/content/productlist?title=' +
          this.searchKey +
          '&pi=' +
           this.pageNumber +
          '&ps=' +
          this.pageSize +
          '&cate_id=' +
          this.cate_id,
      )
      .subscribe(res => {
        this.list = res.data.list;
        this.total = res.data.pagination.total;
        this.loading = false;
        this.cdr.detectChanges();
      });
  }
  add() {
    this.drawer
      .create<ContentProlistEditComponent, { addObj: any }, string>({
        nzTitle: '添加图文信息',
        nzContent: ContentProlistEditComponent,
        nzWidth: '80%',
        nzContentParams: {
          addObj: { id: 0, catelist: this.nodes },
        },
      })
      .afterClose.subscribe(() => {
        this.getData();
      });
  }

  edit(item) {
    this.drawer
      .create<ContentProlistEditComponent, { editObj: any }, string>({
        nzTitle: '编辑图文信息',
        nzContent: ContentProlistEditComponent,
        nzWidth: '80%',
        nzContentParams: {
          editObj: { catelist: this.nodes, editList: item },
        },
      })
      .afterClose.subscribe(() => {
        this.getData();
      });
  }
  deleteItem(id) {
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该图文信息?</b>',
      nzContent: '<p>删除该图文将导致永久性删除，是否继续?</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/content/deleteproduct', {
            id: id,
          })
          .subscribe((res: any) => {
            console.log(res);
            if (res.code === 1) {
              this.message.success('删除成功');
              this.getData();
            } else {
              this.message.error(res.message);
            }
          });
      },
    });
  }
}
