import { Component, OnInit,Input,Output, EventEmitter,ElementRef,ChangeDetectorRef,ChangeDetectionStrategy } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { filter } from 'rxjs/operators';
import { NzMessageService,UploadFile, NzFormatEmitEvent,NzModalService, NzTreeNode } from 'ng-zorro-antd';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { CookieService } from "ngx-cookie-service";
import { Observable, Observer } from 'rxjs/index';
// import { EventEmitter } from 'events';


@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.less'],
})
export class AppPictureComponent implements OnInit {
  @Input() imageList: any[];
  @Input() image: any[];
  @Input() uedit: any[];
  @Output() imageEvent:EventEmitter<any> = new EventEmitter()
  ueditFlag: boolean=true;
  loading = true;
  isVisible = false;
  activedNode: NzTreeNode;
  picsize:string;
  title:string;
  nodes:any[] = [];
  list:any[]= [];
  count:any = 0;
  visible = false;
  uploading = false;
  fileList: UploadFile[] = [];
  categoryid = '0';
  searchKey = '';
  isVisible1 = false;
  showImgName:string;
  showImgUrl:string;
  categoryName = '全部分类';
  picUrl:string;

  //批量筛选
  isListFlag = [];
  listFlag = [];
  //传值的图片路径与id
  mediaList= [];

  get sid(){
    return this.cookies.get('sid');
  }

  constructor(
    private cookies: CookieService,
    private http: _HttpClient,
    private msg: NzMessageService,
    private https: HttpClient,
    private modalService: NzModalService,
    private cdr: ChangeDetectorRef,
    private modal: ModalHelper) { }


   ///点击分类，进行查询
   activeNode(data: NzFormatEmitEvent){
    this.activedNode = data.node!;
    this.categoryid = this.activedNode.key;
    this.categoryName = this.activedNode.origin.name;
    this.getPicList(1);
  }


  ngOnInit() {
    this.picUrl = environment.SPAPPS_URL + '/backend/web/v1/commons/upload-pic?sid=' + this.sid;
    this.getTreeData();
    this.getPicList(1);
    if (this.imageList) {
      this.mediaList = this.imageList;
    } else if (this.image) {
      this.mediaList = this.image
      console.log(this.image)
    } else if (this.uedit) {
      this.ueditFlag = false;
      this.mediaList = this.uedit
    }
  }
     // 获取左侧图片分类
  getTreeData() {
    this.http.get(environment.SPAPPS_URL + '/backend/web/v1/categories?symbol=pic_cate&sid='+this.sid).subscribe(res => {
      this.nodes = res.data;
    });
  }

  //获取图片列表
  getPicList(page: number){
    this.loading = true;
      this.http.get(environment.SPAPPS_URL+'/backend/web/v1/medias?sid='+this.sid
      +'&category_id='+this.categoryid+'&name='+this.searchKey+'&page='+page+'&per-page=12'
      ).subscribe((res: any) => {
        res.data.list.forEach(ele => {
          ele['listFlag'] = false;
        })
        this.list = res.data.list;
          this.count = res.data.pagination.total;
          this.picsize = res.data.total_size;
          this.title = this.categoryName +" ("+ this.count +")";
      });
      this.loading = false;
  }

  //显示图片弹框
  showModal(){
    this.isVisible = true;
    this.isListFlag = [];
    this.listFlag = [];
  }
  //多选
  checklist(item) {
    item.listFlag = !item.listFlag;
    if (this.image) {
      this.mediaList[0] = {
        media: item.media_id,
        url:item.url
      }
      this.isVisible = false;
          //重置列表样式
      this.list.forEach(ele => {
        ele.listFlag = false
      })
    } else {
      this.isListFlag.push({
        id: item.media_id,
        flag: item.listFlag,
        url:item.url
      })
   }
  }

  // 确定选择图片
  handleOk2() {
    this.isVisible = false;
    this.isListFlag.reverse()
      //数组去重
    let hash={}
    this.isListFlag = this.isListFlag.reduce((pre, cur) => {
      hash[cur.id] ? "" : (hash[cur.id] = true && pre.push(cur))
      return pre
    },[])
    this.isListFlag.reverse()
    this.isListFlag.forEach(ele => {
      if (ele.flag == true) {
        const item = {
          media: ele.id,
          url:ele.url
        }
        this.listFlag.push(item)
      }
    })
    //重置列表样式
    this.list.forEach(ele => {
      ele.listFlag = false
    })
    // 相册多图
    if (this.imageList) {
      this.mediaList = this.mediaList.concat(this.listFlag)
    }else if(this.uedit){
    //如果富文本图片,只添加新增的部分
      this.uedit = this.listFlag;
      this.imageEvent.emit(this.uedit)
    }
  }
  //查看图片以及显示弹框
  getImgView(data: any) {
    this.showImgName = data.name;
    this.showImgUrl = data.url;
    this.isVisible1 = true;
  }
  //批量删除
  deleteList() {
    let arr = [];
    this.listFlag.reverse()
      //数组去重
    let hash={}
    this.listFlag = this.listFlag.reduce((pre, cur) => {
      hash[cur.id] ? "" : (hash[cur.id] = true && pre.push(cur))
      return pre
    },[])
    this.listFlag.forEach(ele => {
      if (ele.flag == true) {
        arr.push(ele.id)
      }
    })
    this.modalService.confirm({
      nzTitle: '<b>是否确定批量删除该图片?</b>',
      nzContent: '<p>删除该图片会导致使用到的该图片无法被加载，是否任要继续删除?</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/commons/del-pic',{
            "media_ids": arr,
            "type":2
          })
          .subscribe((res: any) => {
            if (res.code === 1) {
              this.msg.success('删除成功');
              this.getPicList(1);
            } else {
              this.msg.error(res.message);
            }
          });
      },
    });
  }


  ///删除图片
  deleteItem(item:any){
    this.modalService.confirm({
      nzTitle: '<b>是否确定删除该图片?</b>',
      nzContent: '<p>删除该图片会导致使用到的该图片无法被加载，是否任要继续删除?</p>',
      nzOnOk: () => {
        this.http
          .post(environment.SPAPPS_URL + '/backend/web/v1/commons/del-pic',{
            "media_id":item.media_id,
            "type":1,
          })
          .subscribe((res: any) => {
            if (res.code === 1) {
              this.msg.success('删除成功');
              this.getPicList(1);
            } else {
              this.msg.error(res.message);
            }
          });
      },
    });
  }
  searchList(){
    this.getPicList(1);
  }

  beforeUpload = (file: File) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/bmp';
      if (!isJPG) {
        this.msg.error('仅支持上传jpg、jpeg、png、bmp 格式图片文件!');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024  < 1;
      if (!isLt2M) {
        this.msg.error('上传图片文件必须小于1M，请压缩以后再上传 !');
        observer.complete();
        return;
      }
    });
  };

//上传图片
  handleChange(info: { file: UploadFile }): void {
    console.log(info)
    // console.log(file)
  switch (info.file.status) {
    case 'uploading':
      this.loading = true;
      break;
    case 'done':
      this.getPicList(1);
      break;
    case 'error':
      this.msg.error('图片上传失败，系统未知错误，请联系客服进行反馈!');
      this.loading = false;
      break;
  }
}
}
