import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { SFSchemaEnumType } from '@delon/form';
import { environment } from '@env/environment';
import { ContentParamslistEditComponent} from './edit/edit.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-content-paramslist',
  templateUrl: './paramslist.component.html',
})
export class ContentParamslistComponent implements OnInit {
  url = environment.SPAPPS_URL + '/backend/web/v1/article/articlenamelist?sid='+this.sid;

  searchSchema: SFSchema = {
    properties: {
      category: {
        type: 'string',
        title: '分类筛选',
        ui: {
          widget: 'tree-select',
          width: 260,
          allowClear: true,
          placeholder: '请选择所属分类',
          dropdownStyle: { height: '200px' },
          defaultExpandAll: true,
          asyncData: () => this.getTreeData(),
        },
      },
    }
  };
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: '编号', index: 'id'},
    { title: '参数名称', index: 'name' },
    { title: '所属分类', index: 'cate_name',className: 'text-center' },
    { title: '是否通用', type: 'yn', index: 'is_gm',className: 'text-center' },
    {
      title: '操作',
      buttons: [
         { text: '编辑', click: (item: any) => this.editParams(item) },
         { text:'删除',click:(item:any)=> this.deleteParams(item) }
      ]
    }
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cookies: CookieService,
    private messageService: NzMessageService,
    private modalService:NzModalService
  ) { }

  ngOnInit() { }

    //获取sid
    get sid() {
      return this.cookies.get('sid');
    }

    editParams(record:any){
        this.modal.createStatic(ContentParamslistEditComponent,{record}).subscribe(
          (res:any)=>{
              this.st.reload();
          }
        );
    }


    ///删除参数
  deleteParams(item: any) {
      console.log(item)
      this.modalService.confirm({
        nzTitle: '是否确认删除该信息',
        nzContent: '删除以后无法恢复，删除前请慎重考虑!',
        nzOnOk: () => {
          this.http.post(environment.SPAPPS_URL + "/backend/web/v1/article/deletearticlename", {
            "id": item.id,
          }).subscribe(res => {
            if (res.code == 1) {
              this.messageService.success("删除成功")
              this.st.reload();
            } else {
              this.messageService.error(res.message)
            }
          })
        }
      })
    }

    // 获取父节点
    getTreeData() {
      return this.http.get(environment.SPAPPS_URL + '/backend/web/v1/contentcate/listcontentcate?sid=' + this.sid).pipe(
        map((v, i) => {
          const arr: SFSchemaEnumType[] = [];
          (v as any).data.list.forEach(element => {
            arr.push(element);
          });
          return arr;
        }),
      );
    }

  add() {
    this.modal
      .createStatic(ContentParamslistEditComponent, { i: { id: 0 } })
      .subscribe(() => this.st.reload());
  }

}
