import { Component, OnInit } from '@angular/core';
import { NzMessageService, NzDrawerRef } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';
import { ContentImagesComponent } from '../../images/images.component';

@Component({
  selector: 'app-content-categorylist-edit',
  templateUrl: './edit.component.html',
})
export class ContentCategorylistEditComponent implements OnInit {
  validateForm: FormGroup;
  nodes: any = [];
  editData: any = {};
  editParent: string;
  avatarUrl: string;
  loading = false;
  media_id = 0;

  constructor(
    private cookies: CookieService,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private fb: FormBuilder,
    private msg: NzMessageService,
    private drawerRef: NzDrawerRef,
    private modal: ModalHelper,
  ) {}
  get sid() {
    return this.cookies.get('sid');
  }

  ngOnInit(): void {
    this.getTreeData();
    this.avatarUrl = this.editData.url;
    //关键
    if (this.editData.parent === null) {
      this.editParent = '';
    } else {
      this.editParent = this.editData.parent.key;
    }
    // 表单验证
    this.validateForm = this.fb.group({
      parent: [null],
      name: [null, [Validators.required]],
      second_name: [null],
      icon: [null],
      link: [null],
      intro: [null],
      status: [null, [Validators.required]],
      type: [null],
      sort: [null, [Validators.required]],
    });
  }
  // 获取父节点
  getTreeData() {
    this.http
      .get(environment.SPAPPS_URL + '/backend/web/v1/contentcate/listcontentcate?sid='+this.sid+'&id='+this.editData.id+'$type=tree')
      .subscribe(res => {
        this.nodes = res.data.list;
      });
  }
  // 表单提交
  submitForm(): void {
    console.log(this.validateForm.valid);
    if (!this.validateForm.valid) {
      for (const i in this.validateForm.controls) {
        console.log(this.validateForm.controls[i]);
        this.validateForm.controls[i].markAsDirty();
        this.validateForm.controls[i].updateValueAndValidity();
      }
    } else {
      this.http
        .post(environment.SPAPPS_URL + '/backend/web/v1/contentcate/editcontentcate', {
          id: this.editData.key,
          parent: this.editData.parent,
          name: this.editData.name,
          second_name: this.editData.second_name,
          icon: this.media_id.toString(),
          intro: this.editData.intro,
          link: this.editData.link,
          status: this.editData.status,
          type: this.editData.type,
          sort: this.editData.sort,
          sid: this.sid,
        })
        .subscribe((res: any) => {
          console.log(res);
          if (res.code !== 1) {
            this.msg.error(res.message);
            return;
          }
          this.msgSrv.success('操作成功，已成功保存数据.');
          this.drawerRef.close();
        });
    }
  }

  checkImages() {
    this.modal.createStatic(ContentImagesComponent, { size: 'md' }).subscribe((res: any) => {
      console.log(res);
      this.avatarUrl = res.url;
      this.media_id = res.media_id;
    });
  }
}
