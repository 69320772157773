import { Component, OnInit, ViewChild,ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent } from '@delon/abc';
import { NzDrawerService,NzModalService,NzMessageService } from 'ng-zorro-antd';
import { HomepageBannerViewComponent } from "./view/view.component";
import { environment } from '@env/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-homepage-banner',
  templateUrl: './banner.component.html',
})
export class HomepageBannerComponent implements OnInit {
  islogin= true;
  dataList: any[] = [];
  pageNumber = 1;
  pageSize= 10;
  searchKey="";
  ids: any;
  @ViewChild('st') st: STComponent;
  columns: STColumn[] = [
    { title: "ID", index: "id", width: "15%" ,className:'text-center'},
    { title: '图片', type: 'img', width: "15%", index: 'banner_img',className:'text-center' },
    { title: '名称', index: 'name' ,width:"15%",className:'text-center'},
    { title: '跳转类型',index: 'link_type_name',width:"15%",className:'text-center'},
    { title: '跳转地址',index: 'link_id_name',width:"15%",className:'text-center'},
    { title: "状态", type: 'yn' ,index:"status",width:"15%",className:'text-center'},
    {
      title: '操作', buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.delete(item.id) },
      ],width:"15%",className:'text-center'
    },
  ];

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private drawerService: NzDrawerService,
    private msg: NzModalService,
    private messageService:NzMessageService,
    private cdr: ChangeDetectorRef,
    private cookies:CookieService
  ) { }

  ngOnInit() {
    this.getData()
  }
  get sid() {
    return this.cookies.get("sid")
  }
  add() {
    this.drawerService.create<HomepageBannerViewComponent, { addObj: any }, string>({
      nzTitle: "添加轮播图",
      nzContent: HomepageBannerViewComponent,
      nzWidth: '60%',
      nzContentParams: {
        addObj: { add: 1 }
      },
    }).afterClose.subscribe(res => {
      this.st.reload();
    })
  }
  edit(item) {
    this.drawerService.create<HomepageBannerViewComponent, { editObj: any }, string>({
      nzTitle: "编辑轮播图",
      nzContent: HomepageBannerViewComponent,
      nzWidth: "60%",
      nzContentParams: {
        editObj:{item:item}
      }
    }).afterClose.subscribe(res => {
      this.getData();
      this.cdr.detectChanges()
    })
  }
  delete(id) {
    this.msg.confirm({
      nzTitle: '是否确认删除该信息',
      nzContent: '删除以后无法恢复，删除前请慎重考虑!',
      nzOnOk: () => {
        this.http.delete(environment.SPAPPS_URL + "/backend/web/v1/banner/" + id).subscribe(res => {
          if (res.code == 1) {
            this.messageService.success(res.message)
            this.getData()
          } else {
            this.messageService.error(res.message)
          }
        })
      }
    })
  }

  search() {
    this.getData();
    this.searchKey = "";
  }
  getData() {
    this.http.get(environment.SPAPPS_URL + "/backend/web/v1/banner" + "?keyword="+this.searchKey).subscribe(res => {
      this.islogin = false;
      console.log("banner", res)
      if (res.code == 1) {
        this.dataList=res.data.list
      }
    })
  }
  getRest() {
    this.searchKey = "";
    this.getData();
  }
}
